.mx-icon {
	font-family: "mapy";
	font-style: normal;
	font-weight: normal;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@errorColor: #c00;
