.map-buttons {
	position: absolute;
	top: 17px;
	left: 17px;
	z-index: 10;

	button {
		float: left;
		border-radius: 2px;
		background: #fff;
		font-size: 13px;
		line-height: 24px;
		height: 27px;
		padding: 0 8px;
		margin: 0;
		border: none;
		vertical-align: middle;
		box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
		user-select: none;
		-webkit-touch-callout: none;
		cursor: pointer;
		color: #6b7580;
		width: 100px;
		outline: none;

		&:hover {
			color: #29ac07;
		}
	}

	.mapset {
		margin-right: 10px;
		width: auto;

		span {
			padding-left: 6px;
			position: relative;
			top: -1px;
		}

		&:before {
			.mx-icon();
			content: "m";
			display: inline-block;
			position: relative;
			top: 1px;
			font-size: 16px;
		}
	}

	.map-button {
		&.map {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		&.table {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		&.active {
			color: #29ac07;
			background-color: #fff;
			background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…IgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
			background-image: linear-gradient(to bottom, #f1f1f1 0, #fff 68%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f1f1', endColorstr='#ffffff', GradientType=0);
		}
	}
}
