@import "../../css/mixins.less";

header {
	flex: 0 1 auto;
}

h1 {
	margin: 0;

	a {
		margin-top: 10px;
		display: inline-block;

		img {
			border: none;
			margin-left: -3px;
			margin-top: 4px;
		}

		&.hidden {
			visibility: hidden;
		}
	}

	.back {
		color: #6b7580;
		border: none;
		font-size: 13px;
		padding: 0 15px;
		margin: 0;
		position: absolute;
		left: 0;
		top: 0;
		line-height: 48px;
		height: 48px;
		vertical-align: baseline;
		cursor: pointer;
		font-weight: normal;

		&:before {
			.mx-icon();
			position: relative;
			top: -1px;
			padding: 4px;
			width: 18px;
			height: 18px;
			background: #6b7580;
			color: #fff;
			content: "b";
			font-size: 10px;
			margin-right: 5px;
			border-radius: 3px;
		}

		&:hover {
			color: #29AC07;
		}

		&:hover:before {
			background: #29AC07;
		}
	}
}

h2.title {
	font-size: 21px;
	text-align: center;
}
