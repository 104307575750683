/** legenda  **/
.legend-container {
	text-align: center;
	margin-top: 15px;
}

/* table holder pro legendu */
.legend-items {
	display: flex;
	flex-direction: column;
	gap: 20px;

	.legend-item {
		display: flex;
		align-items: center;
		gap: 20px;

		.icon-cover.legend {
			flex: 1 1 50%;
			display: flex;
			justify-content: flex-end;

			span {
				margin: 0;
			}
		}

		.trans {
			flex: 1 1 50%;
			font-size: 13px;
			text-align: left;
		}
	}
}
