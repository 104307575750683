
/* vygenerovany less soubor pro legendy git@gitlab.kancelar.seznam.cz:mapycz/legendy.git */
.legend {
	span {
		display: block;
		background: url(/static/img/haptic-sprite.png) no-repeat;
		margin: 5px auto;
		&.brook {
			width: 48px;
			height: 9px;
			background-position: 0 -154px;
		}
		&.buildings {
			width: 43px;
			height: 23px;
			background-position: 0 -131px;
		}
		&.builtuparea {
			width: 52px;
			height: 30px;
			background-position: 0 -71px;
		}
		&.busstop {
			width: 10px;
			height: 19px;
			background-position: -161px -62px;
		}
		&.cableway {
			width: 54px;
			height: 8px;
			background-position: -102px -154px;
		}
		&.church {
			width: 37px;
			height: 26px;
			background-position: -161px 0;
		}
		&.class1road {
			width: 60px;
			height: 6px;
			background-position: -43px -147px;
		}
		&.greenarea {
			width: 43px;
			height: 30px;
			background-position: -52px -71px;
		}
		&.industrialarea {
			width: 51px;
			height: 41px;
			background-position: 0 -30px;
		}
		&.motorway {
			width: 60px;
			height: 9px;
			background-position: -86px -131px;
		}
		&.motorwaynumber {
			width: 23px;
			height: 15px;
			background-position: -161px -47px;
		}
		&.parking {
			width: 12px;
			height: 16px;
			background-position: -182px -26px;
		}
		&.railway {
			width: 54px;
			height: 7px;
			background-position: -86px -140px;
		}
		&.railwaystation {
			width: 75px;
			height: 14px;
			background-position: -83px -101px;
		}
		&.road {
			width: 60px;
			height: 5px;
			background-position: -109px -163px;
		}
		&.seat {
			width: 36px;
			height: 36px;
			background-position: -125px 0;
		}
		&.stairs {
			width: 44px;
			height: 14px;
			background-position: -83px -115px;
		}
		&.stateborder {
			width: 59px;
			height: 6px;
			background-position: -50px -163px;
		}
		&.streetpath {
			width: 125px;
			height: 30px;
			background-position: 0 0;
		}
		&.towndistrict {
			width: 41px;
			height: 30px;
			background-position: 0 -101px;
		}
		&.townname {
			width: 21px;
			height: 21px;
			background-position: -161px -26px;
		}
		&.tram {
			width: 43px;
			height: 16px;
			background-position: -43px -131px;
		}
		&.wall {
			width: 54px;
			height: 8px;
			background-position: -48px -154px;
		}
		&.water {
			width: 51px;
			height: 38px;
			background-position: -51px -30px;
		}
		&.waterarea {
			width: 42px;
			height: 29px;
			background-position: -41px -101px;
		}
		&.watercourse {
			width: 50px;
			height: 8px;
			background-position: 0 -163px;
		}
	}
}
