.layout {
	display: flex;
	width: 100%;
	height: 100%;
}

.map-area {
	flex: 1 1 auto;
	background: #fff;
	position: relative;
}

.panel-area {
	flex: 0 0 400px;
	position: relative;

	.shadow-area {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		box-shadow: 0 0 2px rgb(0 0 0 / 30%);
		z-index: 1;
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		overflow: auto;
	}
}

body.gray-mode .panel-area {
	background: var(--body-bg-gray);
}

#block-map {
	position: absolute;
	left: 0;
	right: 0;
	cursor: pointer;
	z-index: 10;

	p {
		padding-left: 20px;
		font-size: 16px;
		line-height: 20px;
		color: #29ac07;

		&:before {
			font-family: "mapy";
			font-style: normal;
			font-weight: normal;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			color: #29ac07;
			content: "l";
			font-size: 20px;
			display: inline-block;
			vertical-align: top;
			padding-right: 10px;
		}
	}
}

.layout.responsive-mode.layout-mode {
	#block-map {
		top: 0;
		height: 75px;
		background: transparent;
	}

	.smap .hud,
	.map-buttons .map-button {
		display: none;
	}
}

.layout.responsive-mode.map-mode {
	#block-map {
		bottom: 0;
		height: 52px;
		background: white;
	}
}

@media only screen and (max-width: 789px) {
	.layout {
		flex-direction: column;
	}

	.layout.responsive-mode.layout-mode {
		.map-area {
			height: 75px;
			flex: 0 0 75px;
		}

		.panel-area {
			height: auto;
			flex: 1 1 auto;
			overflow: auto;
			box-shadow: 0 0 2px rgb(0 0 0 / 30%);
		}
	}

	.layout.responsive-mode.map-mode {
		.panel-area {
			height: 52px;
			flex: 0 0 52px;
		}

		.map-area {
			height: auto;
			flex: 1 1 auto;
			overflow: auto;
			box-shadow: 0 0 2px rgb(0 0 0 / 30%);
		}
	}
}
