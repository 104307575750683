html {
	height: -webkit-fill-available;
}

body {
	margin: 0;
	padding: 0;
	height: 100vh;
	height: -webkit-fill-available;
	min-height: 100vh;
	min-height: -webkit-fill-available;
	font-family: Arial;
	font-size: 14px;
	overflow: auto;
	--body-bg-default: #fff;
	--body-bg-gray: #f8f8f8;
	background-color: #fff;
	background-size: auto;

	&.gray-mode {
		background-color: var(--body-bg-gray);
	}
}

button {
	cursor: pointer;

	& > * {
		/* no contents of a button should get its own clicks etc. */
		pointer-events: none;

		/* rules for IE11 that prevent the 1px movement of contents while button is active */
		position: relative;
		top: 0;
		left: 0;
	}

	/* remove the default focused look in Firefox */
	&::-moz-focus-inner {
		border: 0;
	}
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-track {
	background: #ffffff;
}

::-webkit-scrollbar-thumb {
	background: #8a8a8a;
	border-radius: 4px;
}

* {
	scrollbar-color: #8a8a8a #ffffff;
	scrollbar-width: thin;
}

#app {
	position: fixed;
	overflow: auto;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}

a {
	color: #239906;
	font-size: 14px;
	text-decoration: none;
	outline: none;

	&:hover {
		text-decoration: underline;
	}

	&:focus {
		text-decoration: underline;
	}
}

.blind,
.hide {
	display: none;
}

.for-blind {
	position:absolute;
	left:-1000px;
	top:auto;
	width:1px;
	height:1px;
	overflow:hidden;
}

.smap .copyright a {
	font-size: 10px;
}

.sbrowser-bar .map-button {
	top: 56px;
}

.animated-balloon {
	background: url('/static/img/a-normal.png') 0 0 no-repeat;

	&:hover {
		z-index: 1;
		background-position: -84px 0;
	}
}

.lock-popup-cover {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,.3);
	z-index: 20;
	display: flex;
	justify-content: center;
	align-items: center;

	.lock-popup {
		background: #fff;
		width: 400px;
		border-radius: 3px;
		border: none;
		flex: 0 0 auto;
		padding: 25px;

		.main-print-button {
			margin-bottom: 0;
		}
	}
}

// upravy
.hud .zoom {
	right: 19px;
	top: 17px;
	width: 87px !important;

	.button-group button {
		width: 43px !important;
	}
}

label.custom {
	cursor: pointer;
}

@import "./css/mixins.less";
@import "./css/custom.less";
@import "./css/map-buttons.less";
@import "./css/legend.less";
@import "./css/controlZoom.less";
