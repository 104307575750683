@import "../../css/mixins.less";

/** formular **/
.search-form {
	&.hide-from-legend {
		display: none;
	}

	input[type=text] {
		box-sizing: border-box;
		width: 100%;
		border-radius: 3px;
		line-height: 22px;
		height: 36px;
		padding: 5px 77px 5px 11px;
		font-size: 18px;
		font-weight: normal;
		border-right: 0 none;
		background-color: white;
		box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.10);
		border-radius: 3px;
		border: 1px solid #cdcdcd;

		&:focus {
			border-color: #29ac07;
			outline: none;
		}

		&:focus + input {
			background-color: #239906;
			background-image: linear-gradient(-179deg, #29ac07 0, #29ac07 100%);
		}
	}

	input[type=submit] {
		box-shadow: inset 0px -1px 2px 0px rgba(0,0,0,0.10);
		margin: 0;
		padding: 0;
		text-align: center;
		overflow: hidden;
		font-size: 16px;
		font-family: "mapy";
		font-style: normal;
		font-weight: normal;
		display: inline-block;
		color: #fff;
		background-color: #9aa6b2;
		background-image: linear-gradient(-179deg, #9aa6b2 0, #818d99 100%);
		border: none;
		border-radius: 0 3px 3px 0;
		cursor: pointer;
		width: 40px;
		height: 36px;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		line-height: 1;
		position: absolute;
		right: 20px;
	}

	.clear-search {
		transition: opacity 200ms;
		right: 60px;
		line-height: 30px;
		height: 36px;
		width: 26px;
		position: absolute;
		outline: none;
		cursor: pointer;
		background-color: transparent;

		&:before {
			.mx-icon();
			content: "c";
			font-size: 16px;
			position: relative;
			top: 4px;
			color: #aeaeae;
		}
	}
}
