@import "../../css/mixins.less";

#mapset-switch {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	box-sizing: border-box;
	background-color: #fff;
	background-color: rgba(255, 255, 255, 0.8);
	padding: 60px 17px 17px 12px;
	overflow: auto;

	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
		max-width: 630px;

		li {
			text-align: center;
			display: inline-block;
			border: 1px solid #e7e7e7;
			color: #6b7580;
			background-color: #fff;
			margin: 1px 5px 11px;
			font-size: 14px;
			line-height: 26px;
			cursor: pointer;
			position: relative;
			width: 142px;

			img {
				display: block;
				padding: 1px 1px 1px 1px;
			}

			&:hover {
				border-color: #29AC07;
			}

			&.active {
				width: 140px;
				border: 2px solid #29AC07;
				font-weight: bold;
				color: #29AC07;

				&:before {
					position: absolute;
					top: 0;
					left: 0;
					width: 140px;
					height: 43px;

					padding-top: 22px;
					content: "h";
					.mx-icon();
					font-size: 56px;
					color: #29AC07;
					background-color: rgba(255, 255, 255, 0.6);
				}

				img {
					padding: 0;
				}
			}

			&.tip:after {
				content: url(/img/mapset/tip.png);
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}
}
