.custom {
	display: inline-block;
	height: 24px;
	line-height: 24px;

	input {
		position: absolute;
		opacity: 0; /* visibility:hidden to vyhodi z taborderu */

		& + span {
			padding: 0 0 0 26px;
			height: 24px;
			display: inline-block;
			background: url("/static/img/input.png") 0 0 no-repeat;
		}
	}

	input[type=radio] + span						{ background-position: 0 0px; }
	input[type=checkbox] + span						{ background-position: 0 -144px; }

	/* Hover & Focus styles */
	.custom:hover input[type=radio] + span,
	input[type=radio]:focus + span					{ background-position: 0 -24px; }
	.custom:hover input[type=checkbox] + span,
	input[type=checkbox]:focus + span 				{ background-position: 0 -168px; }

	/* Checked styles */
	input[type=radio]:checked + span 				{ background-position: 0 -72px; }
	.custom:hover input[type=radio]:checked + span,
	input[type=radio]:focus:checked + span 			{ background-position: 0 -96px; }
	input[type=checkbox]:checked + span 			{ background-position: 0 -216px; }
	.custom:hover input[type=checkbox]:checked + span,
	input[type=checkbox]:focus:checked + span 		{ background-position: 0 -240px; }

	/* Disabled styles */
	input[type=radio]:disabled + span				{ background-position: 0 -48px; }
	input[type=radio]:disabled:checked + span		{ background-position: 0 -120px; }
	input[type=checkbox]:disabled + span			{ background-position: 0 -192px; }
	input[type=checkbox]:disabled:checked + span	{ background-position: 0 -264px; }
}
