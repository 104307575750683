div.table {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #efefef;

	.centered {
		flex: 1;

		table {
			border-collapse: collapse;
			margin: 0 auto;
		}

		td {
			border: 1px solid #e1e1e1;
			padding: 8px;

			&.filled {
				background: #FFF;
			}

			a {
				display: block;
				font-size: 12px;
				color: #111;
			}
		}

		td[colspan] {
			text-align: center;
		}

		input {
			display: block;
			margin: auto;
		}

		td[rowspan]:first-child {
			text-align: right;
		}

		td[rowspan], td[colspan] {
			border-style: dotted;
		}

		tr:first-child td:first-child,
		tr:first-child td:last-child,
		tr:last-child td:first-child,
		tr:last-child td:last-child {
			border: none;
		}

		.arrow {
			.arrow-outer {
				border: none;
				display: inline-block;
				background: #FFF;
				padding: 12px;
				border-radius: 3px;
				border-color: #999;
				box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);

				.caption {
					color: #239906;
					font-size: 14px;
				}
			}

			.arrow-icon {
				font-family: "mapy";
				display: block;
				color: #239906;
				font-size: 1.6em;
			}

			&.up {
				a {
					margin-bottom: 10px;
				}

				.arrow-icon {
					margin-bottom: 5px;
				}
			}

			&.down {
				a {
					margin-top: 10px;
				}

				.arrow-icon {
					margin-top: 5px;
				}
			}

			&.left {
				a {
					margin-right: 10px;
				}

				.arrow-icon {
					display: inline-block;
					vertical-align: top;
				}

				.arrow-outer .caption {
					display: inline-block;
					vertical-align: top;
					margin-left: 5px;
					margin-top: 2px;
				}
			}

			&.right {
				a {
					margin-left: 10px;
				}

				.arrow-icon {
					display: inline-block;
					vertical-align: top;
				}

				.arrow-outer .caption {
					display: inline-block;
					vertical-align: top;
					margin-right: 10px;
					margin-top: 2px;
				}
			}

			&.left .arrow-outer,
			&.right .arrow-outer {
				padding-top: 20px;
				padding-bottom: 20px;
				min-width: 110px;
			}

			&.up .arrow-outer,
			&.down .arrow-outer {
				padding-left: 20px;
				padding-right: 20px;
				padding: 7px 20px;
			}
		}

		.custom {
			text-align: center;
			width: 100%;

			span:first-child {
				display: inline-block;
			}

			span.name {
				display: block;
			}
		}

		td.arrow {
			border-style: none;

			a {
				display: inline-block;
			}
		}

		&.table-of {
			.arrow .arrow-outer .caption {
				display: none;
			}

			.arrow .arrow-outer {
				min-width: 0;
			}
		}
	}
}
