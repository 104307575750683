.haptic-zooms {
	position: absolute;
	top: 17px;
	right: 130px;
	z-index: 10;
	border-radius: 2px;
	background-color: #fff;
	box-shadow: 0 4px 16px 0 rgba(0,0,0,0.2);

	button {
		font-size: 13px;
		line-height: 15px;
		color: #6B7580;
		padding: 6px 16px;
		display: inline-block;
		vertical-align: top;
		background-color: #fff;
		border: none;
		outline: none;
		cursor: pointer;

		&.active {
			color: #1EAE00;
			background-color: #F8F8F8;
		}

		.title {
			display: inline-block;
		}

		.responsive {
			display: none;
		}
	}

	.separator {
		display: inline-block;
		width: 1px;
		height: 19px;
		vertical-align: top;
		margin-top: 3px;
		background-color: #E8E8E8;
	}
}

/* media query */
@media only screen and (max-width: 1100px) {
	.haptic-zooms {
		button .title {
			display: none;
		}

		button .responsive {
			display: inline-block;
		}
	}
}

@media only screen and (max-width: 1024px) {
	.haptic-zooms {
		right: 20px;
		top: 60px;
	}

	.map-buttons .map-button {
		width: auto;
	}
}
