@import "../../css/mixins.less";

/** tisk **/
.main-print-button {
	display: block;
	width: 100%;
	color: #fff;
	cursor: pointer;
	background-color: #29AC07;
	box-shadow: inset 0 -1px 2px 0 rgba(0,0,0,0.2), 0 0 2px 0 #29AC07;
	border: 1px solid transparent;
	border-radius: 3px;
	margin: 0;
	padding: 0;
	text-align: center;
	overflow: hidden;
	font-size: 14px;
	width: 100%;
	height: 32px;
	font-weight: bold;
	line-height: 32px;
	outline: none;
	margin-bottom: 8px;

	&:disabled {
		opacity: 0.7;
		cursor: default;
	}
}

#print {
	margin-top: 35px;
	padding: 5px;
	color: black;

	&.hide {
		display: none;
	}

	label {
		font-size: 13px;
		line-height: 24px;
	}

	h2 {
		margin: 0;
		font-size: 20px;
		position: relative;

		button:not(.arrow-outer) {
			border: none;
			background: transparent;
			position: absolute;
			right: 0;
			top: 3px;

			svg {
				fill: #999;
				width: 14px;
				height: 14px;
			}
		}
	}

	p {
		margin: 0.5em 0;
		font-size: 14px;

		span {
			font-size: 14px;
		}

		.custom span {
			padding-left: 27px;
		}

		&:not(.print-title) {
			margin: 12px 0;
		}

		&.print-title {
			font-size: 13px;
			line-height: 24px;
			font-weight: bold;
			margin-top: 25px;
		}

		&.pois {
			margin-left: 30px;
		}

		&.list-id {
			margin-bottom: 5px;
		}

		&.reset {
			margin-top: 25px;
		}
	}

	.map-name-area {
		display: table;
		width: 100%;

		label {
			display: table-cell;
			width: 90px;
		}

		input[type="text"] {
			display: table-cell;
			width: 100%;
			padding: 9px 12px 11px 12px;
			box-sizing: border-box;
			box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.1);
			border-radius: 3px;
			border: 1px solid #cdcdcd;
			font-size: 14px;
			line-height: 20px;

			&:active,
			&:focus {
				border-color: #29ac07;
				outline: none;
			}
		}
	}

	.separator-line {
		margin: 15px 0 25px 0;
		width: 100%;
		height: 1px;
		background: #cdcdcd;
	}

	.reset.options {
		margin-top: 5px;

		.list-id {
			float: left;
			width: 35%;
			height: 90px;
			margin-top: 7px;
		}

		table {
			margin: 0 auto;

			.first {
				padding-left: 25px;
				padding-right: 25px;
			}
		}

		.custom {
			float: left;
		}
	}

	#areas {
		color: #666;
	}

	.custom input+span {
		padding: 0 0 0 32px;
	}

	.download-info {
		display: block;
		margin: 10px 0;
		text-align: center;

		&:empty {
			display: none;
		}

		&.error {
			color: @errorColor;
		}
	}
}
