/** prepinac jazyka */
.lang-switcher {
	position: absolute;
	right: 32px;
	top: 16px;
	z-index: 10;
	background: var(--body-bg-default);
	text-align: left;

	.lang-switcher-item {
		background: var(--body-bg-default);
		border: none;
		text-align: left;
		display: flex;
		border-radius: 14px;
		padding: 2px 10px 2px 2px;

		.icon {
			width: 20px;
			height: 20px;
			flex: 0 0 20px;
			overflow: hidden;
			border-radius: 100%;
			border: 1px solid #ccc;

			img {
				width: 20px;
				height: 20px;
			}
		}

		.name {
			flex: 1 1 auto;
			margin-top: 3px;
			margin-left: 4px;
			margin-right: 4px;
			font-size: 13px;
			color: #6b7580;
		}

		.arrow-up,
		.arrow-down {
			flex: auto;
			width: 0;
			height: 0;
			border-style: solid;
			margin-top: 7px;
		}

		.arrow-down {
			border-width: 5px 5px 0 5px;
			border-color: #666666 transparent transparent transparent;
		}

		.arrow-up {
			border-width: 0 5px 5px 5px;
			border-color: transparent transparent #666666 transparent;
		}

		&:hover {
			color: #29ac07;

			.arrow-down {
				border-color: #29ac07 transparent transparent transparent;
			}

			.arrow-up {
				border-color: transparent transparent #29ac07 transparent;
			}
		}
	}

	ul {
		padding: 0;
		margin: 0;
		border-radius: 3px;
		border: 1px solid #ccc;
		box-shadow: 0 2px 8px rgb(0 0 0 / 5%);
		position: absolute;
		left: -7px;
		bottom: -5px;
		transform: translateY(100%);
		z-index: 2;
		background: #fff;
		min-width: 110px;

		li {
			list-style: none;

			.lang-switcher-item {
				padding: 6px 8px;
				width: 100%;
				background: #fff;

				.name {
					margin-left: 8px;
				}

				&:hover {
					background: #f8f8f8;
				}
			}
		}
	}
}

body.gray-mode .lang-switcher {
	background: var(--body-bg-gray);

	> .lang-switcher-item {
		background: #f0f0f0;
	}
}
