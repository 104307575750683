/** paticka /*/
footer {
	text-align: center;
	flex: 0 1 auto;
	padding: 20px 0;

	a {
		font-size: 11px;
		color: #999;

		&:hover {
			color: #29ac07;
		}
	}

	.separator {
		color: #cbcbcb;
		margin: 0px 4px;
	}
}

body.gray-mode footer {
	background-color: var(--body-bg-gray);
}
