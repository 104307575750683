@import "../../css/mixins.less";

.user-form,
.last-step {
	&.hide {
		display: none;
	}

	h2 {
		text-align: center;
		font-size: 24px;
		line-height: 32px;
		margin-top: 0;
	}

	p {
		font-size: 14px;
		line-height: 20px;
	}

	h3 {
		font-size: 14px;
		line-height: 24px;
		margin-top: 20px;

		span {
			font-weight: normal;
			display: inline-block;
			margin-left: 0.5em;
		}
	}

	.form-line {
		margin-bottom: 16px;

		.caption {
			width: 33%;
			display: inline-block;
			vertical-align: top;
			font-size: 14px;
			line-height: 40px;

			.title {
				display: block;
			}

			.optional {
				display: block;
				color: #6B7580;
				font-size: 12px;
				font-style: italic;
				line-height: 21px;
				margin-top: -10px;
			}
		}

		.input-holder {
			width: 66%;
			display: inline-block;
			vertical-align: top;

			input {
				display: block;
				width: 100%;
				box-sizing: border-box;
				border: 1px solid #ccc;
				border-radius: 4px;
				background-color: #fff;
				box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.2);
				font-size: 14px;
				line-height: 20px;
				height: 40px;
				outline: none;
				padding: 0 12px;
				color: black;

				&[aria-invalid="true"],
				&.error {
					border-color: @errorColor;
					border-width: 2px;
				}

				&.error + p.error {
					display: block;
				}
			}

			span.error {
				color: @errorColor;
				margin: 0;
				margin-top: 5px;
				font-size: 12px;
				line-height: 16px;
			}

			textarea {
				width: 100%;
				resize: none;
				box-sizing: border-box;
				border: 1px solid #ccc;
				border-radius: 4px;
				box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.2);
				height: 80px;
				outline: none;
				padding: 12px;
			}

			textarea[aria-invalid="true"] {
				border-color: @errorColor;
				border-width: 2px;
			}

			textarea.error + p.error {
				display: block;
			}
		}
	}

	.gdpr-link {
		font-size: 14px;
		line-height: 24px;
		color: #1EAE00;
	}

	.step-back {
		border: none;
		background: transparent;
		display: block;
		color: #1EAE00;
		font-size: 14px;
		line-height: 18px;
		margin: 25px 0;
		cursor: pointer;

		span,
		svg {
			display: inline-block;
			vertical-align: middle;
		}

		svg {
			width: 18px;
			height: 18px;
			fill: #1EAE00;
			margin-right: 8px;
		}
	}

	input[type=checkbox].error + span {
		color: @errorColor;
		border: 1px solid @errorColor;
		border-radius: 3px;
	}

	.send-info {
		margin: 10px 0;
		text-align: center;

		&:empty {
			display: none;
		}

		&.error {
			color: @errorColor;
		}
	}
}

.last-step .main-print-button {
	margin-top: 40px;
}
