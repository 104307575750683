@import "../../css/mixins.less";

/** vysledky **/
.search-results {
	margin: 0;
	padding: 0;
	margin-top: 15px;
	margin-left: -20px;
	width: calc(100% + 40px);

	label span {
		position: absolute;
		overflow: hidden;
		width: 0;
	}

	.search-msg {
		text-align: center;
		padding: 20px;
	}

	li {
		cursor: pointer;
		padding: 0;
		border-bottom: 1px solid #e1e1e1;
		list-style: none;
		padding: 10px 20px;

		.inner {
			display: flex;

			.icon {
				width: 52px;
				background-position: center center;
				background-repeat: no-repeat;
				border-radius: 4px;
				background-color: #ebebeb;
				flex: 0 0 50px;
				max-height: 50px;

				&.default:before {
					.mx-icon();
					font-size: 16px;
					color: #777;
					display: inline-block;
					position: relative;
					top: -2px;
					left: 20px;
					content: "K";
				}
			}

			.content {
				flex: 1 1 auto;
				padding: 0;
				padding: 0 15px;

				h3 {
					font-size: 14px;
					line-height: 18px;
					margin: 0 0 2px 0;
					font-weight: bold;
				}

				p {
					font-size: 12px;
					line-height: 16px;
					color: #000;
					margin: 0;

					&.location {
						color: gray;
					}
				}
			}
		}

		&:hover {
			background-color: #f4f4f4;

			h3 {
				background-color: #f4f4f4;
			}
		}

		&.empty {
			padding: 10px;
			text-align: center;
			cursor: default;
		}
	}
}
