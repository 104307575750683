/** promo **/
.promo {
	margin-top: 25px;
	padding: 10px;

	.promo-img {
		width: 340px;
	}

	p {
		color: #000;
		line-height: 16px;
		font-size: 14px;

		a {
			font-size: 14px;
			line-height: 20px;
			color: #29ac07;
		}
	}
}

@media only screen and (max-width: 789px) {
	.promo {
		text-align: center;
	}
}
